import { Tabs as AntTabs, TabsProps as AntTabsProps } from 'antd';

interface TabsProps extends AntTabsProps {
  testId?: string;
}

export const Tabs = (props: TabsProps) => {
  const { testId, onChange, items, tabBarStyle, ...rest } = props;

  const handleTabChange = (key: string) => {
    if (onChange) {
      onChange(key);
    }
  };

  return (
    <AntTabs
      data-testid={testId || 'tabs'}
      defaultActiveKey="1"
      items={items}
      onChange={handleTabChange}
      tabBarStyle={{ margin: 0, ...tabBarStyle }}
      {...rest}
    />
  );
};
